import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Session } from "../models";
import { Button } from "./Common/Button";

interface Props {
  session: Session;
  handleClick: Function;
}
const SelectConsultType = ({ session, handleClick }: Props) => {
  const [consultType, setConsultType] = useState(session.consult?.name);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const updateButtonIsActive = useCallback((): void => {
    setIsButtonActive(consultType != null);
  }, [consultType]);

  const handleChange = (e: any): void => {
    const { value } = e.target;
    setConsultType(value);
    updateButtonIsActive();
  };

  const validate = () => {
    if (!isEmpty(consultType)) {
      handleClick(consultType);
    }
  };

  useEffect(() => {
    updateButtonIsActive();
  });

  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div className="cs-chkin-form-content step col-12 d-flex flex-column rowGap-2">
          <h3 className="cs-chkin-form-step-heading">Select a consult type</h3>

          <label htmlFor="standard_consult" className="cursor-pointer d-block">
            <span className="radio-consult-type d-flex">
              <input
                id="standard_consult"
                type="radio"
                name="consult"
                data-req="true"
                className="valid"
                data-type="standard"
                value="standard"
                defaultChecked={"standard" === session.consult?.name}
                onClick={handleChange}
              />
              <label id="standard_consult_label" htmlFor="standard_consult">
                Standard telehealth consult ($
                <span className="standard">{session.standardPrice}</span>)
              </label>
            </span>

            <span className="description d-block">
              A GP consult for up to 10 minutes to discuss a health issue or
              condition.
            </span>
          </label>

          <label htmlFor="priority_consult" className="cursor-pointer d-block">
            <span className="radio-consult-type d-flex">
              <input
                id="priority_consult"
                type="radio"
                name="consult"
                data-req="true"
                className="valid"
                data-type="priority"
                value="priority"
                defaultChecked={"priority" === session.consult?.name}
                onClick={handleChange}
              />
              <label id="priority_consult_label" htmlFor="priority_consult">
                Priority consult ($
                <span className="priority">{session.priorityPrice}</span>)
              </label>
            </span>

            <span className="description d-block">
              Get ahead of the queue to minimise waiting time.
            </span>
          </label>

          <div className="blue-content-box">
            <img
              src="images/svg/Icon_Information_blue.svg"
              className="align-self-start mr-2"
              alt="information icon"
            />
            <div className="body-text black-80">
              Please note we do not backdate medical certificates or provide
              prescriptions for S8 or S4D medications. An Individual Health Care
              Identifier (IHI) is required for electronic prescriptions. Without
              an IHI or Medicare card, prescriptions cannot be issued by Our
              Sage.
            </div>
          </div>

          <div className="form-group">
            <button
              className="btn btn-lg btn-primary js-btn-prev d-none"
              type="button"
              title="Prev"
            ></button>
            <Button
              label="Next"
              handleClick={validate}
              isLoading={false}
              isActive={isButtonActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectConsultType;

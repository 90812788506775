import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CheckboxControl,
  InputControl,
  RadioButtonControl,
  SelectControl
} from "./Common";
import { PatientDetails, Session, ValidatePatientForm } from "../models";
import {
  isDateValid,
  isDateStringAfterToday,
  MapObjectToFormData,
  maskEmail,
  maskMobile,
  sendGAEvent,
  isDateDOBFormat,
  FormatDOB,
  METHOD,
  CallApi
} from "../helpers";
import isEmpty from "lodash/isEmpty";
import SingleNameModal from "./SingleNameModal";
import NoMatchingDetailsModal from "./NoMatchingDetailModal";
import {
  InitialValidatePatientForm,
  EXISTING_PATIENT,
  NEW_PATIENT
} from "../constants";
import { Button } from "./Common/Button";
import { useAlertContext } from "../contexts";

interface Props {
  newPatient: boolean;
  patient: PatientDetails;
  session: Session;
  nextClick: Function;
  restartAsNewPatientClick: Function;
}

const ValidatePatient = React.memo(
  ({
    newPatient,
    patient,
    session,
    nextClick,
    restartAsNewPatientClick
  }: Props) => {
    const [validatePatientForm, setValidatePatientForm] =
      useState<ValidatePatientForm>(InitialValidatePatientForm);
    const lNameLabel1 = "Patient's last name (as per Medicare if available)*";
    const lNameLabel2 = "Patient's name (as per Medicare if available)*";
    const [lNameLabel, setLnameLabel] = useState(
      validatePatientForm.singleName ? lNameLabel2 : lNameLabel1
    );
    const [isButtonActive, setIsButtonActive] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [focusName, setFocusName] = useState("");
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [displaySingleNameModal, setDisplaySingleNameModal] = useState(false);
    const [displayNoMatchingDetailsModal, setDisplayNoMatchingDetailsModal] =
      useState(false);
    const { showAlert, clearAlert } = useAlertContext();

    const originalRequiredProps = ["fName", "lName", "dob", "gender"];

    const requiredProps = useRef(["fName", "lName", "dob", "gender"]);

    const genderOptions = [
      {
        id: "genderm",
        value: "M",
        label: "Male"
      },
      {
        id: "genderf",
        value: "F",
        label: "Female"
      },
      {
        id: "gendero",
        value: "O",
        label: "Other"
      }
    ];

    const genderIdentityOptions = [
      {
        value: "",
        label: ""
      },
      {
        value: "Female",
        label: "Female"
      },
      {
        value: "Male",
        label: "Male"
      },
      {
        value: "Non-binary",
        label: "Non-binary"
      },
      {
        value: "Gender diverse",
        label: "Gender diverse"
      },
      {
        value: "Transgender",
        label: "Transgender"
      },
      {
        value: "Different identity",
        label: "Different identity"
      }
    ];

    const handleChange = (e: any): void => {
      const { name, value } = e.target;
      let newPatientDetails = {
        ...validatePatientForm,
        [name]: value
      };
      // If the singleName check box was clicked clear fName and remove fName from requiredProps
      if (name === "singleName") {
        if (value) {
          newPatientDetails = {
            ...newPatientDetails,
            fName: ""
          };
          requiredProps.current = requiredProps.current.filter(
            (prop) => prop !== "fName"
          );
        } else {
          // Add fName back to required props
          if (!requiredProps.current.includes("fName")) {
            requiredProps.current = ["fName", ...requiredProps.current];
          }
        }
      }
      setValidatePatientForm(newPatientDetails);
      updateButtonIsActive(newPatientDetails);
      setFocusName("");
    };

    const handleSingleNameClick = (e: any): void => {
      setDisplaySingleNameModal(true);
    };

    const singleNameModalHandleClose = (e: any, reason: any) => {
      if (e.target?.value !== null) {
        // User clicked on a button
        setLnameLabel(e.target.value ? lNameLabel2 : lNameLabel1);
        handleChange(e);
      } else if (reason !== null) {
        // User clicked on backdrop or pressed escape so set singleNmae checkbox back to original value
        handleChange({
          name: "singleName",
          value: !validatePatientForm.singleName
        });
      }
      setDisplaySingleNameModal(false);
    };

    const noMatchingDetailsModalClose = (e: any) => {
      const value = e.target.value;
      setDisplayNoMatchingDetailsModal(false);
      if (value === "newpatient") {
        restartAsNewPatientClick(patient);
      }
    };

    const validFname = (validatePatientForm: ValidatePatientForm): boolean => {
      return (
        validatePatientForm.singleName || !isEmpty(validatePatientForm.fName)
      );
    };

    const validLname = (validatePatientForm: ValidatePatientForm): boolean => {
      return !isEmpty(validatePatientForm.lName);
    };

    const validDOB = (validatePatientForm: ValidatePatientForm): boolean => {
      return (
        isDateValid(validatePatientForm.dob) &&
        !isDateStringAfterToday(validatePatientForm.dob)
      );
    };

    const validGender = useCallback(
      (validatePatientForm: ValidatePatientForm): boolean => {
        return !newPatient || !isEmpty(validatePatientForm.gender);
      },
      [newPatient]
    );

    const updateButtonIsActive = (
      patientDetails: ValidatePatientForm
    ): void => {
      let isButtonActive = true;
      const map = new Map(Object.entries(patientDetails));
      requiredProps.current.forEach((prop) => {
        if (isEmpty(map.get(prop))) {
          isButtonActive = false;
        }
      });
      if (!validDOB(patientDetails)) {
        isButtonActive = false;
      }
      if (!validGender(patientDetails)) {
        isButtonActive = false;
      }
      setIsButtonActive(isButtonActive);
    };

    const validate = () => {
      if (isLoading) {
        return;
      }
      let errorsExist = false;
      setFocusName("");
      setValidationErrors([]);

      const validationErrors: string[] = [];
      const addError = (fieldName: string) => {
        if (!errorsExist) {
          setTimeout(() => {
            setFocusName(fieldName);
          });
        } else {
          validationErrors.push(fieldName);
        }
        errorsExist = true;
      };

      if (!validFname(validatePatientForm)) {
        addError("fName");
      }

      if (!validLname(validatePatientForm)) {
        addError("lName");
      }

      if (!validDOB(validatePatientForm)) {
        addError("dob");
      }

      if (!validGender(validatePatientForm)) {
        validationErrors.push("gender");
        errorsExist = true;
      }

      if (errorsExist) {
        setTimeout(() => setValidationErrors(validationErrors));
      } else {
        callValidatePatient();
      }
    };

    const callValidatePatient = async () => {
      try {
        clearAlert();
        const body = MapObjectToFormData({
          ...validatePatientForm,
          cid: session.cid.toString()
        });
        const response = await CallApi(
          "val_patient",
          METHOD.POST,
          body,
          null,
          setIsLoading,
          setIsError
        );

        if (session.patientType === EXISTING_PATIENT && !response.status) {
          // display modal
          setDisplayNoMatchingDetailsModal(true);
          return;
        } else {
          const patientDetails =
            session.patientType === NEW_PATIENT && !response.status
              ? validatePatientForm
              : response;
          const maskedEmail = maskEmail(validatePatientForm.email);
          const maskedMobile = maskMobile(validatePatientForm.mobileNo);
          let newPatientDetails = {
            ...patientDetails,
            singleName: validatePatientForm.singleName,
            maskedEmail,
            maskedMobile
          };

          sendGAEvent({
            event: "patient_type",
            step: 2,
            step_name: "Patient Type",
            user_id: newPatientDetails.maskedEmail,
            extra: {
              new_patient: !response.status
            }
          });

          sendGAEvent({
            event: "patient_details",
            step: 3,
            step_name: "Patient Details",
            user_id: newPatientDetails.maskedEmail,
            extra: {
              gender: newPatientDetails.gender,
              birthday_year: newPatientDetails.dob.substring(6)
            }
          });
          nextClick(newPatientDetails);
        }
      } catch (error: any) {
        showAlert({
          message: "Error fetching patient details",
          alertType: "error"
        });
      }
    };

    useEffect(() => {
      if (newPatient) {
        requiredProps.current = originalRequiredProps;
      } else {
        requiredProps.current = originalRequiredProps.slice(0, 3);
      }

      if (!isEmpty(patient)) {
        let patientForm = {
          ...patient,
          dob:
            !isEmpty(patient.dob) && !isDateDOBFormat(patient.dob)
              ? FormatDOB(patient.dob)
              : patient.dob
        };
        setValidatePatientForm(patientForm);
        if (patient.singleName) {
          requiredProps.current = requiredProps.current.filter(
            (prop) => prop !== "fName"
          );
        }
        updateButtonIsActive(patientForm);
      } else {
        updateButtonIsActive(validatePatientForm);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPatient, patient, session]);

    useEffect(() => {
      if (isError) {
        showAlert({
          message: "Error fetching patient details",
          alertType: "error"
        });
      } else {
        clearAlert();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    return (
      <>
        <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
          <div className="cs-chkin-form-content step col-xs-11">
            <h3 className=" cs-chkin-form-step-heading">
              Great, we'll need some details
            </h3>
            {!validatePatientForm.singleName && (
              <InputControl
                type="text"
                label="Patient's first name (as per Medicare if available)*"
                name="fName"
                value={validatePatientForm.fName}
                onChange={handleChange}
                focus={focusName === "fName"}
              />
            )}

            <InputControl
              type="text"
              label={lNameLabel}
              name="lName"
              value={validatePatientForm.lName}
              onChange={handleChange}
              focus={focusName === "lName"}
              error={validationErrors.includes("lName")}
            />

            <CheckboxControl
              id="singleName"
              name="singleName"
              label="The patient only has a first name or last name"
              checked={validatePatientForm.singleName}
              onClick={handleSingleNameClick}
            />

            <InputControl
              type="date"
              label="Patient's date of birth (dd/mm/yyyy)*"
              id="dob"
              name="dob"
              value={validatePatientForm.dob}
              onChange={handleChange}
              focus={focusName === "dob"}
              error={validationErrors.includes("dob")}
            />

            {newPatient && (
              <div className="col-sm-12 mt-4 pl-0">
                <RadioButtonControl
                  label="Patient's birth Sex"
                  includeAsterix={true}
                  name="gender"
                  value={validatePatientForm.gender}
                  options={genderOptions}
                  onChange={handleChange}
                  error={validationErrors.includes("gender")}
                />

                <SelectControl
                  id="gender_label"
                  name="gender_identity"
                  value={validatePatientForm.gender_identity}
                  label="Patient's gender identity"
                  options={genderIdentityOptions}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className="form-group">
              <button
                className="btn btn-lg btn-primary js-btn-prev d-none"
                type="button"
                title="Prev"
              ></button>
              <Button
                label="Next"
                handleClick={validate}
                isLoading={isLoading}
                isActive={isButtonActive}
              />
            </div>
          </div>
        </div>

        <SingleNameModal
          open={displaySingleNameModal}
          handleClose={singleNameModalHandleClose}
        />

        <NoMatchingDetailsModal
          open={displayNoMatchingDetailsModal}
          handleClose={noMatchingDetailsModalClose}
        />
      </>
    );
  }
);

export default ValidatePatient;

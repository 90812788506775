import { Box, Modal } from "@mui/material";
import React from "react";
import { ModalStyle } from "../constants";

interface Props {
  open: boolean;
  handleClose: (event: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

const CancelModal = React.memo(({ open, handleClose }: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <div className="d-flex flex-column  align-items-center rowGap-2">
          <img src="images/svg/Icon_Red_Alert.svg" alt="alert" />
          <h3 id="cancelModalLabel" className="modal-heading">
            Cancel this consult
          </h3>
          <div className="modal-text">
            See &nbsp;
            <a
              href="https://oursage.zendesk.com/hc/en-au"
              target="_blank"
              rel="noreferrer"
            >
              Online Help
            </a>
            &nbsp; for more information about cancellation.
          </div>

          <div
            style={{
              display: "flex",
              gap: 10,
              marginTop: "24",
              justifyContent: "center"
            }}
          >
            <button
              className="btn modal-btn-outline btn-stay d-inline-block"
              type="button"
              onClick={() => handleClose(new Event("stay"))}
            >
              Stay
            </button>
            <button
              className="btn modal-btn-solid btn-cancel-consult d-inline-block bg-danger"
              type="button"
              onClick={() => handleClose(new Event("cancel"))}
            >
              Cancel Consult
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
});

export default CancelModal;

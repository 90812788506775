import { useEffect, useRef, useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import AlertBar from "./components/AlertBar";
import RegistrationType from "./components/RegistrationType";
import ValidatePatient from "./components/ValidatePatient";
import {
  ConsultTypesMap,
  CSConfig,
  EXISTING_PATIENT,
  InitialSession,
  NEW_PATIENT,
  sessionData
} from "./constants";
import {
  timeout,
  toggleChatbot,
  BuildUrl,
  createOurSageLog,
  logOurSageEvent,
  MapDeepObjectToQueryString,
  FormatName,
  CallApi,
  METHOD,
  sendGAEvent
} from "./helpers";
import {
  PatientDetails,
  SendVerificationCodeForm,
  ContactDetailsForm,
  EmergencyNextOfKinForm,
  ConfirmConsult,
  Step,
  Session,
  SpaInit
} from "./models";
import SendVerificationCode from "./components/SendVerificationCode";
import { isEmpty } from "lodash";
import EnterVerificationCode from "./components/EnterVerificationCode";
import ContactDetails from "./components/ContactDetails";
import EmergencyNextOfKin from "./components/EmergencyNextOfKin";
import MedicareDetails from "./components/MedicareDetails";
import SelectConsultType from "./components/SelectConsultType";
import ContactNumber from "./components/ContactNumber";
import ConfirmConsultDetails from "./components/ConfirmConsultDetails";
import EmergencyPrompt from "./components/EmergencyPrompt";
import Footer from "./components/Footer";
import { useAlertContext } from "./contexts";
import { useHttp } from "./hooks";
import Payment from "./components/Payment";
import PaymentFailedModal from "./components/PaymentFailedModal";
import CancelModal from "./components/CancelModal";
import { WaitingRoom } from "./components/WaitingRoom";
import { ConsultComplete } from "./components/ConsultComplete";

declare global {
  interface Window {
    dataLayer: any[];
    zE: any;
  }
}

export const App = () => {
  const cid = Number(process.env.REACT_APP_CID);
  const [activeStep, setActiveStep] = useState(0);
  const [completedStepPercent, setCompletedStepPercent] = useState(8.5);
  const [remainingStepPercent, setRemainingStepPercent] = useState(91.5);
  const [heading, setHeading] = useState("Telehealth consult");
  const [enablePrevButton, setEnablePrevButton] = useState(false);
  const { showAlert, clearAlert } = useAlertContext();
  const [session, setSession] = useState<Session>(InitialSession);
  const selectedPatientType = useRef(0);
  const [patientDetails, setPatientDetails] = useState<PatientDetails>(
    {} as PatientDetails
  );

  const [displayPaymentFailedModal, setDisplayPaymentFailedModal] =
    useState(false);
  const [displayCancelModal, setDisplayCancelModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableCancel, setEnableCancel] = useState(true);

  const {
    data: spaInitResponse,
    isError: isSpaInitError,
    fetchData: getSpaInit
  } = useHttp<SpaInit>(BuildUrl("spa_init"), {
    method: "GET",
    queryString: `cid=${cid}&type=consult`
  });

  const checkQueueStatusTimer = useRef<any>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCheckinAvailable, setIsCheckinAvailable] = useState(true);

  const initialise = async () => {
    try {
      toggleChatbot(false);
      getSpaInit();
      getUserCookieCheckin();
    } catch (error: any) {
      toggleErrorMessage(true);
    }
  };

  useEffect(() => {
    setSession({
      ...session,
      cid: cid,
      priorityPrice: Number(process.env.REACT_APP_PRIORITY_CONSULT_PRICE),
      standardPrice: Number(process.env.REACT_APP_STANDARD_CONSULT_PRICE),
      checkinMessage: spaInitResponse?.checkin_message || "",
      waitText: spaInitResponse?.wait_text || "",
      feeText: spaInitResponse?.fee_text || ""
    });
    if (spaInitResponse?.checkin_message || spaInitResponse?.wait_text) {
      showAlert({
        message: spaInitResponse.checkin_message || spaInitResponse.wait_text,
        alertType: spaInitResponse?.checkin_message ? "error" : "success"
      });
    }
    if (spaInitResponse?.checkin_message) {
      setIsCheckinAvailable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaInitResponse]);

  const getUserCookieCheckin = async () => {
    try {
      const body = MapDeepObjectToQueryString({
        sessinfo: {
          cid: session.cid,
          pinfo: { prn: patientDetails.prn }
        }
      });

      const response = await CallApi(
        "get_user_cookie_checkin",
        METHOD.POST,
        body,
        null,
        setIsLoading,
        setIsError,
        true
      );

      if (isError) {
        toggleErrorMessage(true);
        return;
      }

      if (response && response.prn > 0) {
        setSession({
          ...session,
          contactNumber: response.contact_number,
          email: response.email,
          consult: ConsultTypesMap.get(response.consult_type)
        });
      }

      return true;
    } catch (e) {
      toggleErrorMessage(true);
    }
  };

  useEffect(() => {
    toggleErrorMessage(isError || isSpaInitError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSpaInitError]);

  const toggleErrorMessage = (error: boolean): void => {
    if (error) {
      showAlert({
        message: "There has been a problem. Please try again later.",
        alertType: "error"
      });
    } else {
      clearAlert();
    }
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const selectEmergency = () => {
    nextStep(Step.PatientType);
  };

  const selectRegistrationType = (patientType: number) => {
    selectedPatientType.current = patientType;
    setSession({
      ...session,
      patientType: patientType
    });
    nextStep(Step.Patient);
  };

  const validatePatientNextClick = async (response: any) => {
    if (response) {
      setPatientDetails(response as PatientDetails);
      if (
        selectedPatientType.current === NEW_PATIENT &&
        !isEmpty(response.prn)
      ) {
        selectedPatientType.current = EXISTING_PATIENT;
        setSession({
          ...session,
          patientType: EXISTING_PATIENT
        });
      }
    }
    nextStep(Step.SendCode);
  };

  const restartAsNewPatient = (validatePatientForm: PatientDetails) => {
    setPatientDetails({ ...validatePatientForm });
    setSession({
      ...session,
      patientType: NEW_PATIENT
    });
    selectedPatientType.current = NEW_PATIENT;
    setActiveHeading(Step.Patient);
  };

  const sendVerificationCodeNextClick = (
    sendVerificationCodeForm: SendVerificationCodeForm
  ) => {
    try {
      setPatientDetails({
        ...patientDetails,
        mobileNo: sendVerificationCodeForm.mobileNo,
        email: sendVerificationCodeForm.email,
        maskedMobile: sendVerificationCodeForm.maskedMobile,
        maskedEmail: sendVerificationCodeForm.maskedEmail
      });
      setSession({
        ...session,
        verificationCode: sendVerificationCodeForm.verificationCode,
        vopt: sendVerificationCodeForm.vopt
      });
      nextStep(Step.VerifyCode);
    } catch (e) {
      toggleErrorMessage(true);
    }
  };

  const enterVerificationNextClick = (success: boolean) => {
    if (success) {
      setSession({
        ...session,
        authorised: true
      });
      nextStep(Step.ContactDetails);
    }
  };

  const contactDetailsNextClick = (contactDetailsForm: ContactDetailsForm) => {
    const newPatientDetails = {
      ...patientDetails,
      suburb: contactDetailsForm.suburb,
      address: contactDetailsForm.address,
      pcode: contactDetailsForm.pcode
    };
    setPatientDetails(newPatientDetails);
    nextStep(Step.EmerContact);
  };

  const emergencyNextOfKinNextClick = (
    emergencyNextOfKinForm: EmergencyNextOfKinForm
  ) => {
    const newPatientDetails = {
      ...patientDetails,
      emer_fname: emergencyNextOfKinForm.emer_fname,
      emer_lname: emergencyNextOfKinForm.emer_lname,
      emer_contact_no: emergencyNextOfKinForm.emer_contact_no,
      emer_rel: emergencyNextOfKinForm.emer_rel,
      kin_fname: emergencyNextOfKinForm.kin_fname,
      kin_lname: emergencyNextOfKinForm.kin_lname,
      kin_contact_no: emergencyNextOfKinForm.kin_contact_no,
      kin_rel: emergencyNextOfKinForm.kin_rel
    };
    setPatientDetails(newPatientDetails);
    nextStep(Step.Medicare);
  };

  const medicareDetailsNextClick = async (patientDetails?: PatientDetails) => {
    if (patientDetails) {
      setPatientDetails(patientDetails);
    }
    nextStep(Step.ConsultType);
  };

  const selectConsultTypeNextClick = async (consultType: string) => {
    const consult = ConsultTypesMap.get(consultType);
    sendGAEvent({
      event: "consult_type",
      step: 9,
      step_name: "Consult Type",
      user_id: patientDetails.maskedEmail,
      extra: {
        consult_type: consult?.label,
        value: consult?.price
      }
    });
    setSession({ ...session, consult });
    nextStep(Step.ContactNo);
  };

  const contactNumberNextClick = async (contactNumber: string) => {
    setSession({
      ...session,
      contactNumber,
      email: session.email || patientDetails.email
    });
    nextStep(Step.Confirm);
  };

  const saveDetailsChangesClick = (confirmConsult: ConfirmConsult) => {
    const consult = ConsultTypesMap.get(confirmConsult.consultType);
    setSession({
      ...session,
      contactNumber: confirmConsult.mobileNo,
      email: confirmConsult.email,
      consult
    });
  };

  const confirmConsultNextClick = (confirmConsult: ConfirmConsult) => {
    const consult = ConsultTypesMap.get(confirmConsult.consultType);
    setSession({
      ...session,
      contactNumber: confirmConsult.mobileNo,
      email: confirmConsult.email,
      consult
    });
    nextStep(Step.Payment);
  };

  const paymentResult = async (success: boolean) => {
    const ourSageLog = createOurSageLog(patientDetails, session);
    ourSageLog.function = "payment pre-authorised";
    ourSageLog.paymentAmount = session.consult?.price;
    ourSageLog.success = success;
    await logOurSageEvent(ourSageLog);

    if (success) {
      await confirmCheckin();
    } else {
      setDisplayPaymentFailedModal(true);
    }
  };

  const confirmCheckin = async () => {
    try {
      const sessinfo = MapDeepObjectToQueryString({
        sessinfo: {
          cid: session.cid,
          display_name: FormatName(patientDetails),
          ctype: session.consult?.order,
          pinfo: {
            prn: patientDetails.prn,
            email: patientDetails.email,
            mobileNo: patientDetails.mobileNo
          },
          IHI: patientDetails.IHI,
          contact_number: session.contactNumber,
          consult_label: session.consult?.label
        }
      });

      const ourSageLog = createOurSageLog(patientDetails, session);
      ourSageLog.function = "confirm_checkin";

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await CallApi(
        "confirm_checkin",
        METHOD.POST,
        sessinfo,
        null,
        setIsLoading,
        setIsError,
        true,
        ourSageLog
      );

      await getQueueStatus();
      nextStep(Step.WaitingRoom);

      clearInterval(checkQueueStatusTimer.current);
      checkQueueStatusTimer.current = setInterval(() => {
        getQueueStatus();
      }, 60000);
    } catch (e) {
      console.log("error in confirmCheckin", e);
      toggleErrorMessage(true);
    }
  };

  const getQueueStatus = async () => {
    try {
      const sessinfo = MapDeepObjectToQueryString({
        sessinfo: {
          cid: session.cid,
          pinfo: { prn: patientDetails.prn },
          docid: "0",
          ctype: session.consult?.order
        }
      });

      const response = await CallApi(
        "chk_q_stat",
        METHOD.POST,
        sessinfo,
        null,
        setIsLoading,
        setIsError,
        true
      );

      if (response && response.status) {
        const queueStatus = Number(response.n);
        setSession({
          ...session,
          queueStatus
        });
        if (queueStatus === -2) {
          clearInterval(checkQueueStatusTimer.current);
          nextStep(Step.ConsultComplete);
          setEnableCancel(true);
        } else if (queueStatus === -1) {
          setEnableCancel(false);
        } else if (queueStatus === -1) {
          setEnableCancel(false);
        }
      }
    } catch (e) {
      console.log("error in getQueueStatus", e);
      toggleErrorMessage(true);
    }
  };

  const consultCompleteNextClick = () => {
    endSession();
  };

  const closePaymentFailedModal = () => {
    setDisplayPaymentFailedModal(false);
    nextStep(Step.Confirm);
  };

  const closeCancelModal = async (event: Event) => {
    setDisplayCancelModal(false);
    if (event.type === "cancel") {
      const result = await cancelCheckin();
      if (result) {
        sendGAEvent({
          event: "cancel_consult_telehealth",
          step: activeStep + 1,
          step_name: "Cancel",
          user_id: patientDetails.maskedEmail
        });
        endSession();
      }
    }
    return;
  };

  const cancelCheckin = async () => {
    try {
      if (!patientDetails.prn) {
        return new Promise(function (resolve, reject) {
          resolve({ result: true });
        });
      }
      if (!enableCancel) {
        return new Promise(function (resolve, reject) {
          resolve({ result: false });
        });
      }
      const body = MapDeepObjectToQueryString({
        sessinfo: {
          cid: session.cid,
          pinfo: { prn: patientDetails.prn }
        }
      });

      const ourSageLog = createOurSageLog(patientDetails, session);
      ourSageLog.function = "cancel_checkin";

      await CallApi(
        "cancel_checkin",
        METHOD.POST,
        body,
        null,
        setIsLoading,
        setIsError,
        true,
        ourSageLog
      );

      if (isError) {
        toggleErrorMessage(true);
        return;
      }
      clearInterval(checkQueueStatusTimer.current);
      return true;
    } catch (e) {
      toggleErrorMessage(true);
    }
  };

  const nextStep = (activeStepNum: number) => {
    if (!isCheckinAvailable) {
      return;
    }
    calculateActiveStep(activeStepNum);
    setActiveStep(activeStepNum);
    setActiveHeading(activeStepNum);
    if (activeStepNum !== 0) {
      clearAlert();
    }
    const displayChatbot = [Step.SendCode, Step.VerifyCode].includes(
      activeStepNum
    );
    toggleChatbot(displayChatbot);
  };

  const calculateActiveStep = async (activeStepNum: number) => {
    const newPercent = (activeStepNum + 1) * (100 / CSConfig.numSteps);
    const start = completedStepPercent;
    const end = newPercent;
    const step = start < end ? 1 : -1;

    for (let i = start; start < end ? i <= end : i >= end; i += step) {
      await timeout(10);
      setCompletedStepPercent(i);
      setRemainingStepPercent(100 - i);
    }
  };

  const setActiveHeading = (stepNumber: number) => {
    const disabledBackButtons = [Step.Emergency, Step.WaitingRoom];

    switch (stepNumber) {
      case Step.Patient:
        setHeading(
          `${
            selectedPatientType.current === NEW_PATIENT ? "New" : "Existing"
          } patient`
        );
        break;
      case Step.SendCode:
        setHeading("Personal verification");
        break;
      case Step.VerifyCode:
        setHeading("Personal verification");
        break;
      case Step.ContactDetails:
        setHeading("Contact details");
        break;
      case Step.EmerContact:
        setHeading("Emergency contacts");
        break;
      case Step.Medicare:
        setHeading("Healthcare details");
        break;
      case Step.ConsultType:
        setHeading("Consult type");
        break;
      case Step.ContactNo:
        setHeading("Contact number");
        break;
      case Step.Confirm:
        setHeading("Confirmation");
        break;
      case Step.Payment:
        setHeading("Payment details");
        break;
      case Step.WaitingRoom:
        setHeading("Waiting room");
        break;
      default:
        setHeading("Telehealth consult");
    }

    if (disabledBackButtons.includes(stepNumber)) {
      setEnablePrevButton(false);
    } else {
      setEnablePrevButton(true);
    }
  };

  const prevButtonClick = () => {
    switch (activeStep) {
      case Step.PatientType:
        showAlert({
          message: session.checkinMessage || session.waitText,
          alertType: "success"
        });
        break;
      case Step.Patient:
        sessionData.rtype = 0;
        sessionData.ortype = 0;
        sessionData.vcode = "";
        clearAlert();
        break;
      default:
        clearAlert();
        break;
    }
    nextStep(activeStep - 1);
  };

  const closeButtonClick = () => {
    if (activeStep === Step.ConsultComplete) {
      endSession();
    } else if (enableCancel) {
      setDisplayCancelModal(true);
    }
  };

  const endSession = async () => {
    setPatientDetails({} as PatientDetails);
    selectedPatientType.current = 0;
    setSession(InitialSession);
    await initialise();
    nextStep(Step.Emergency);
  };

  return (
    <>
      <div className="container">
        <div className="inner-container">
          <Header
            heading={heading}
            completedStepPercent={completedStepPercent}
            remainingStepPercent={remainingStepPercent}
            enablePrevButton={enablePrevButton}
            prevButtonClick={prevButtonClick}
            closeButtonClick={closeButtonClick}
            enableCloseButton={enableCancel}
          />
          <AlertBar />

          <div
            className="form-container"
            style={{
              marginTop: activeStep === Step.Emergency ? `${0}px` : `${90}px`
            }}
          >
            <form className="cs-chkin-form" autoComplete="off">
              {activeStep === Step.Emergency && (
                <EmergencyPrompt onChange={selectEmergency} />
              )}
              {activeStep === Step.PatientType && (
                <RegistrationType registrationType={selectRegistrationType} />
              )}
              {activeStep === Step.Patient && (
                <ValidatePatient
                  newPatient={selectedPatientType.current === NEW_PATIENT}
                  patient={patientDetails}
                  session={session}
                  nextClick={validatePatientNextClick}
                  restartAsNewPatientClick={restartAsNewPatient}
                />
              )}
              {activeStep === Step.SendCode && (
                <SendVerificationCode
                  session={session}
                  patient={patientDetails}
                  nextClick={sendVerificationCodeNextClick}
                />
              )}
              {activeStep === Step.VerifyCode && (
                <EnterVerificationCode
                  session={session}
                  patient={patientDetails}
                  nextClick={enterVerificationNextClick}
                />
              )}
              {activeStep === Step.ContactDetails && (
                <ContactDetails
                  session={session}
                  patient={patientDetails}
                  handleNextClick={contactDetailsNextClick}
                />
              )}
              {activeStep === Step.EmerContact && (
                <EmergencyNextOfKin
                  patientDetails={patientDetails}
                  handleNextClick={emergencyNextOfKinNextClick}
                />
              )}
              {activeStep === Step.Medicare && (
                <MedicareDetails
                  session={session}
                  patient={patientDetails}
                  handleNextClick={medicareDetailsNextClick}
                />
              )}
              {activeStep === Step.ConsultType && (
                <SelectConsultType
                  session={session}
                  handleClick={selectConsultTypeNextClick}
                />
              )}
              {activeStep === Step.ContactNo && (
                <ContactNumber
                  session={session}
                  patient={patientDetails}
                  handleClick={contactNumberNextClick}
                />
              )}
              {activeStep === Step.Confirm && (
                <ConfirmConsultDetails
                  session={session}
                  patient={patientDetails}
                  handleNextClick={confirmConsultNextClick}
                  saveDetailsChangesClick={saveDetailsChangesClick}
                />
              )}
              {activeStep === Step.Payment && (
                <Payment
                  session={session}
                  patient={patientDetails}
                  setIsError={setIsError}
                  feeText={session.feeText}
                  paymentResult={paymentResult}
                />
              )}
              {activeStep === Step.WaitingRoom && (
                <WaitingRoom
                  session={session}
                  patient={patientDetails}
                  enableCancel={enableCancel}
                  cancelClick={() =>
                    enableCancel ? setDisplayCancelModal(true) : null
                  }
                />
              )}
              {activeStep === Step.ConsultComplete && (
                <ConsultComplete handleNextclick={consultCompleteNextClick} />
              )}
            </form>
          </div>
        </div>
        <Footer></Footer>
      </div>

      <PaymentFailedModal
        open={displayPaymentFailedModal}
        handleClose={() => closePaymentFailedModal()}
      />

      <CancelModal
        open={displayCancelModal}
        handleClose={(e) => closeCancelModal(e as Event)}
      />
    </>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { MedicareDetailsForm, PatientDetails, Session } from "../models";
import { CheckboxControl, InputControl } from "./Common";
import { Button } from "./Common/Button";
import { isEmpty } from "lodash";
import { Box, Modal } from "@mui/material";
import { InitialMedicareDetailsForm, ModalStyle } from "../constants";
import {
  CallApi,
  isExpiryBeforeToday,
  isValidExpiry,
  isValidIHINo,
  isValidMedicareLineNo,
  isValidMedicareNo,
  MapObjectToFormData,
  METHOD,
  sendGAEvent
} from "../helpers";
import { useAlertContext } from "../contexts";

interface Props {
  session: Session;
  patient: PatientDetails;
  handleNextClick: Function;
}

const MedicareDetails = ({ session, patient, handleNextClick }: Props) => {
  const [medicareDetailsForm, setMedicareDetailsForm] =
    useState<MedicareDetailsForm>(InitialMedicareDetailsForm);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [focusName, setFocusName] = useState("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [openMedicare, setOpenMedicare] = useState<boolean>(false);
  const [openIHI, setOpenIHI] = useState<boolean>(false);
  const [openInvalidMedicare, setOpenInvalidMedicare] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [disableMedicare, setDisableMedicare] = useState<boolean>(false);
  const { showAlert, clearAlert } = useAlertContext();

  const requiredMedicareProps = useMemo(
    () => ["medicareNo", "medicarelineno", "exp_medi"],
    []
  );

  useEffect(() => {
    setMedicareDetailsForm({
      medicareNo: patient.medicareNo,
      medicarelineno: patient.medicarelineno,
      exp_medi: patient.exp_medi,
      noMedicare: !isEmpty(patient.IHI),
      IHI: patient.IHI
    });
  }, [patient]);

  const handleChange = (e: any): void => {
    const { name, value, checked } = e.target;
    let newMedicareDetails = {} as MedicareDetailsForm;
    if (name === "noMedicare") {
      setDisableMedicare(checked);
      newMedicareDetails = { ...medicareDetailsForm, noMedicare: checked };
    } else {
      newMedicareDetails = { ...medicareDetailsForm, [name]: value };
    }
    setMedicareDetailsForm(newMedicareDetails);
    updateButtonIsActive(newMedicareDetails);
    setFocusName("");
  };

  const updateButtonIsActive = useCallback(
    (medicareDetailsForm: MedicareDetailsForm): void => {
      let isButtonActive = true;
      if (!medicareDetailsForm.noMedicare) {
        const map = new Map(Object.entries(medicareDetailsForm));
        requiredMedicareProps.forEach((prop) => {
          if (isEmpty(map.get(prop))) {
            isButtonActive = false;
          }
          if (
            prop === "exp_medi" &&
            (!isValidExpiry(map.get(prop)) ||
              isExpiryBeforeToday(map.get(prop)))
          ) {
            isButtonActive = false;
          }
        });
      } else if (
        isEmpty(medicareDetailsForm.IHI) ||
        !isValidIHINo(medicareDetailsForm.IHI)
      ) {
        isButtonActive = false;
      }

      setIsButtonActive(isButtonActive);
    },
    [requiredMedicareProps]
  );

  const validate = async (validate: boolean) => {
    let errorsExist = false;
    setFocusName("");
    setValidationErrors([]);

    const validationErrors: string[] = [];
    const addError = (fieldName: string) => {
      if (!errorsExist) {
        setTimeout(() => {
          setFocusName(fieldName);
        });
      }
      validationErrors.push(fieldName);
      errorsExist = true;
    };

    if (validate) {
      const map = new Map(Object.entries(medicareDetailsForm));
      if (!medicareDetailsForm.noMedicare) {
        requiredMedicareProps.forEach((prop) => {
          if (isEmpty(map.get(prop))) {
            addError(prop);
          } else {
            if (prop === "medicareNo" && !isValidMedicareNo(map.get(prop))) {
              addError(prop);
              setOpenInvalidMedicare(true);
            } else if (
              prop === "medicarelineno" &&
              !isValidMedicareLineNo(map.get(prop))
            ) {
              addError(prop);
            } else if (
              prop === "exp_medi" &&
              (!isValidExpiry(map.get(prop)) ||
                isExpiryBeforeToday(map.get(prop)))
            ) {
              addError(prop);
            }
          }
        });
      } else {
        if (
          isEmpty(medicareDetailsForm.IHI) ||
          !isValidIHINo(medicareDetailsForm.IHI)
        ) {
          addError("IHI");
        }
      }
    }

    if (errorsExist) {
      setTimeout(() => setValidationErrors(validationErrors));
    } else {
      const newPatientDetails = {
        ...patient,
        prn: patient.prn ? patient.prn : "0",
        ...medicareDetailsForm,
        medicareNo: !medicareDetailsForm.noMedicare
          ? medicareDetailsForm.medicareNo
          : "",
        medicarelineno: !medicareDetailsForm.noMedicare
          ? medicareDetailsForm.medicarelineno
          : "",
        exp_medi: !medicareDetailsForm.noMedicare
          ? medicareDetailsForm.exp_medi
          : "",
        IHI: medicareDetailsForm.noMedicare ? medicareDetailsForm.IHI : "",
        cid: session.cid.toString()
      };
      if (
        !patient.prn ||
        Number(patient.prn) <= 0 ||
        !medicareDetailsForm.noMedicare
      ) {
        // Update patient details if they are a new patient or if they entered medicare details
        await saveMedicareDetails(newPatientDetails);
        return;
      }
      handleNextClick(newPatientDetails);
    }
  };

  const saveMedicareDetails = async (newPatientDetails: PatientDetails) => {
    try {
      clearAlert();
      const body = MapObjectToFormData(newPatientDetails);
      const response = await CallApi(
        "updt_details",
        METHOD.POST,
        body,
        null,
        setIsLoading,
        setIsError
      );
      if (response.status) {
        newPatientDetails.prn = response.prn;
        sendGAEvent({
          event: "medicare_details",
          step: 8,
          step_name: "Medicare Details",
          user_id: newPatientDetails.maskedEmail,
          extra: {
            medicare: newPatientDetails.medicareNo?.length > 0
          }
        });
        handleNextClick(newPatientDetails);
      }
    } catch (e) {
      showAlert({
        message: "Error updating patient details",
        alertType: "error"
      });
    }
  };

  useEffect(() => {
    updateButtonIsActive(medicareDetailsForm);
  }, [medicareDetailsForm, updateButtonIsActive]);

  useEffect(() => {
    if (isError) {
      showAlert({
        message: "Error updating patient details",
        alertType: "error"
      });
    } else {
      clearAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div
          className="cs-chkin-form-content step col-12 form-group"
          style={{ maxWidth: "380px" }}
        >
          <h3 className="cs-chkin-form-step-heading">
            Patient's Medicare details
            <i>(optional)</i>
          </h3>

          <div className="blue-content-box mb-4">
            <img
              src="images/svg/Icon_Information_blue.svg"
              className="align-self-start mr-2"
              alt="info icon"
            />
            <p className="body-text black-80 m-0">
              A Medicare rebate will not be available for this telehealth
              consult. By providing your Medicare details, you may be eligible
              for PBS medications and bulk billed pathology, radiology &
              specialist services
            </p>
          </div>

          <div>
            <InputControl
              type="text"
              label="Card Number"
              name="medicareNo"
              value={medicareDetailsForm.medicareNo}
              maxlength={10}
              subtext="10 digits"
              onChange={handleChange}
              disabled={disableMedicare}
              focus={focusName === "medicareNo"}
              error={validationErrors.includes("medicareNo")}
            />
          </div>

          <div className="p-0 m-0">
            <div className="col-lg-5 col-xs-5 p-0 pr-4">
              <InputControl
                type="text"
                label="Line Number"
                name="medicarelineno"
                value={medicareDetailsForm.medicarelineno}
                maxlength={1}
                subtext="1 digit"
                onChange={handleChange}
                disabled={disableMedicare}
                focus={focusName === "medicarelineno"}
                error={validationErrors.includes("medicarelineno")}
              />
            </div>

            <div className="col-lg-7 col-xs-7 p-0">
              <InputControl
                type="expiry"
                label="Expiry Date"
                name="exp_medi"
                value={medicareDetailsForm.exp_medi}
                subtext="mm / yyyy"
                maxlength={9}
                onChange={handleChange}
                disabled={disableMedicare}
                focus={focusName === "exp_medi"}
                error={validationErrors.includes("exp_medi")}
              />
            </div>
          </div>

          <button
            className="btn btn-block btn-text btn-medicare-modal p-0 mb-5 link-text d-flex columnGap-1"
            style={{
              textAlign: "left",
              whiteSpace: "nowrap !important"
            }}
            type="button"
            onClick={() => setOpenMedicare(true)}
          >
            <img
              className="mr-2"
              src="/images/svg/Icon_question_blue_sm.svg"
              alt="small question mark icon"
            />
            Where can I find these details on my Medicare card?
          </button>

          <CheckboxControl
            id="noMedicare"
            name="noMedicare"
            label="I don't have Medicare"
            checked={medicareDetailsForm.noMedicare}
            onClick={handleChange}
          />

          {medicareDetailsForm.noMedicare && (
            <div id="IHIField">
              <h3 className="cs-chkin-form-step-heading">
                Patient's Individual Healthcare Identifier (IHI)
              </h3>

              <div className="blue-content-box mb-4 p-4 columnGap-1">
                <img
                  src="images/svg/Icon_Information_blue.svg"
                  className="align-self-start"
                  alt="info icon"
                />
                <p className="body-text black-80 m-0">
                  An Individual Health Care Identifier (IHI) is required for
                  electronic prescriptions. Without an IHI or Medicare card
                  details, prescriptions cannot be issued by Our Sage.
                </p>
              </div>

              <div>
                <InputControl
                  type="text"
                  label="Individual Healthcare Identifier"
                  name="IHI"
                  value={medicareDetailsForm.IHI}
                  maxlength={16}
                  subtext="16 digits"
                  onChange={handleChange}
                  focus={focusName === "IHI"}
                  error={validationErrors.includes("IHI")}
                />
              </div>

              <button
                className="btn btn-block btn-text btn-IHI link-text p-0 my-4 d-flex columnGap-1"
                type="button"
                onClick={() => setOpenIHI(true)}
              >
                <img
                  className="mr-2"
                  src="/images/svg/Icon_question_blue_sm.svg"
                  alt="small question mark icon"
                />
                Where can I find my IHI?
              </button>
            </div>
          )}

          <div className="form-group">
            <Button
              label="Save"
              handleClick={() => validate(true)}
              isLoading={isLoading}
              isActive={isButtonActive}
            />
            <button
              className="btn btn-lg js-btn-next btn-outline btn-block mt-2 skip"
              id="mediSkip"
              type="button"
              onClick={() => validate(false)}
            >
              Skip for now
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={openMedicare}
        onClose={() => setOpenMedicare(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <button
            className="btn btn-block btn-medicare-modal-close p-2 m-0 text-right"
            style={{ fontSize: 32 }}
            type="button"
            onClick={() => setOpenMedicare(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <img
            src="images/svg/medicare_card.svg"
            className="w-100"
            alt="medicare card"
          />
        </Box>
      </Modal>

      <Modal
        open={openIHI}
        onClose={() => setOpenIHI(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="col-sm-12 text-center mt-3">
            <img
              src="images/svg/Icon_question_blue_sm.svg"
              alt="question mark icon"
            />
          </div>

          <h3 className="modal-heading m-5">
            Where can I find my Individual Healthcare Identifier (IHI)?
          </h3>

          <div className="modal-text">
            Login to your myGov account, where you can find your IHI in the
            Personal Details section of My Health Record. It’s also on your
            COVID-19 Digital Certificate.
          </div>

          <div className="text-center m-5">
            <img
              src="images/svg/IHI.svg"
              className="w-100"
              alt="digital certificate"
            />
          </div>

          <div className="text-center">
            <button
              className="btn modal-btn-outline btn-IHI-modal-close btn-block"
              type="button"
              onClick={() => setOpenIHI(false)}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openInvalidMedicare}
        onClose={() => setOpenInvalidMedicare(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="d-flex flex-column align-items-center">
            <img src="images/svg/Icon_Blue_Alert.svg" alt="alert" />

            <h3 className="modal-heading mb-4">Are your details correct?</h3>

            <p className="modal-text mx-5">
              Please ensure the details entered exactly match what is on your
              Medicare card.
            </p>
            <img
              src="images/svg/medicare_card.svg"
              className="m-3"
              alt="medicare card"
            />

            <button
              className="btn btn-block modal-btn-outline m-4"
              type="button"
              onClick={() => setOpenInvalidMedicare(false)}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MedicareDetails;

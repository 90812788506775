import React, { MouseEventHandler, useEffect, useState } from "react";
import "./RadioButtonControl.scss";

export interface RadioButtonInput {
  id: string;
  label: string;
  value: string;
}

interface Props {
  label: string;
  name: string;
  options: RadioButtonInput[];
  value?: string;
  id?: string;
  onChange: MouseEventHandler<HTMLInputElement>;
  error: boolean;
  highlight?: boolean;
  includeAsterix?: boolean;
}

export const RadioButtonControl = React.memo(
  ({
    label,
    name,
    options,
    value,
    id,
    onChange,
    error,
    highlight,
    includeAsterix
  }: Props) => {
    const [errorClass, setErrorClass] = useState(error ? "shake" : "");

    useEffect(() => {
      let cssClass = "";
      if (highlight || error) {
        cssClass = "error-box";
      }
      if (error) {
        cssClass = cssClass = `${cssClass} shake`;
      }

      setErrorClass(cssClass);
      if (error && cssClass.includes("shake")) {
        setTimeout(function () {
          cssClass = cssClass.replace(/shake/, "");
          setErrorClass(cssClass);
        }, 100);
      }
    }, [error, highlight, name]);

    return (
      <>
        <div className={`mb-5 ${errorClass}`} id={id}>
          <label
            className="body-text mb-4 black-80"
            style={{ fontWeight: 600, fontSize: 14 }}
          >
            {label}
            {includeAsterix && (
              <span className="cs-red" style={{ marginLeft: 4 }}>
                *
              </span>
            )}
          </label>
          <div className="form-inline">
            {options.map((option, index) => (
              <span
                key={`${name}-${option.id}`}
                className={`radio-default d-inline-flex ${
                  index !== 0 ? "ml-5" : ""
                }`}
              >
                <input
                  type="radio"
                  id={`${name}-${option.id}`}
                  name={name}
                  value={option.value}
                  data-req="true"
                  className="valid"
                  checked={value === option.value}
                  onClick={onChange}
                />
                <label htmlFor={`${name}-${option.id}`}>{option.label}</label>
              </span>
            ))}
          </div>
        </div>
      </>
    );
  }
);
